<template>
  <v-container>
    <div class="headline mb-6 mt-1">
      {{ $t('message.admin.group_management') }}
    </div>
    <v-row>
      <v-col cols="12">
        <table-persons
          @create="openCreateGroupModal"
          @edit="goToEditGroup"
          @updatePage="_updatePage"
          @updateSearchText="_findPersonsByText"
          @removeItem="removeGroup"
          @removeMultiplyItem="removeMultiplyGroup"
          :persons="getGroupsList"
          :totalDesserts="table.totalDesserts"
          :totalPages="table.totalPages"
          :currentPage="table.page"
          :loading="groupsLoading"
          :headers="headers"
          ref="tableManagement"
        />
      </v-col>
    </v-row>
    <create-group-modal v-model="createGroupModal.show" />
  </v-container>
</template>

<script>

import { mapGetters } from 'vuex';

import TablePersons from '@components/Admin/TablePersons/TablePersons.vue';
import CreateGroupModal from '@components/Modals/CreateGroupModal.vue';
import tablePersons from '@/mixins/Table/tablePersons';

export default {
  name: 'groupManagement',
  components: {
    TablePersons,
    CreateGroupModal,
  },
  mixins: [
    tablePersons,
  ],
  props: {},
  computed: {
    ...mapGetters({
      groupsLoading: 'groups/isLoading',
      groups: 'groups/getGroups',
    }),
    getGroupsList() {
      return this.groups.data;
    },
  },
  data() {
    return {
      headers: [
        {
          text: '',
          value: 'checkbox',
          sortable: false,
          width: '30px',
        },
        {
          text: window.app.$t('message.admin.id'),
          value: 'id',
          sortable: false,
          width: '30px',
        },
        {
          text: window.app.$t('message.admin.name'),
          value: 'name',
          sortable: false,
        },
        {
          text: window.app.$t('message.admin.group_students'),
          value: 'group_students',
          sortable: false,
        },
        {
          text: window.app.$t('message.admin.actions'),
          value: 'actions',
          sortable: false,
          width: '120px',
        },
      ],
      createGroupModal: {
        show: false,
      },
      tableContainer: null,
      table: {
        page: 1,
        search: null,
        role_id: null,
        totalDesserts: 0,
        totalPages: 0,
      },
    };
  },
  created() {
    this.getDataTable();
  },
  mounted() {
    this.tableContainer = this.$refs.tableManagement;
  },
  methods: {
    getDataTable(params) {
      return this.$api.groups.getGroups(params).then(() => {
        this.table.totalDesserts = this.groups.meta.pagination.total;
        this.table.totalPages = this.groups.meta.pagination.total_pages;
        this.table.page = this.groups.meta.pagination.current_page;
      });
    },
    openCreateGroupModal() {
      this.createGroupModal.show = true;
    },
    goToEditGroup({ id }) {
      this.$router.push(`/admin/group-management/${id}/edit`);
    },
    async removeGroup(idGroup) {
      const confirm = await this.$root.$confirm.open(
        this.$t('message.admin.delete'),
        this.$t('message.admin.are_you_sure_you_want_to_delete_a_group'),
      );

      if (confirm) {
        try {
          await this.$api.groups.removeGroup(idGroup);
          await this._updateTable(this.table);
          this.tableContainer.selected = [];
        } catch (err) {
          if (err.response.status === 404) {
            this.showErrorGroupRemoved();
          }
        }
      }
    },
    showErrorGroupRemoved() {
      this._showErrorNotify(this.$t('message.admin.one_or_several_groups_was_removed_already_please_reload_page'));
    },
    async removeMultiplyGroup(idsGroup) {
      if (!idsGroup.length) {
        this._showErrorNotify(this.$t('message.admin.please_select_groups'));
        return;
      }

      const confirm = await this.$root.$confirm.open(
        this.$t('message.admin.delete'),
        this.$t('message.admin.are_you_sure_you_want_to_delete_a_group'),
      );

      if (confirm) {
        try {
          await this.$api.groups.removeMultiplyGroup({ ids: idsGroup });
          await this._updateTable(this.table);
          this.tableContainer.selected = [];
        } catch (err) {
          if (err.response.status === 404) {
            this.showErrorGroupRemoved();
          }
        }
      }
    },
  },
};
</script>
